<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
    clip-rule="evenodd"
    viewBox="0 0 100 100"
    xml:space="preserve"
  >
    <path
      d="m40.605 61.856.393-28.32 25.812-7.631 16.358 23.243L66.82 72.11 40.605 61.856ZM18.5603 71.54833l-10.256-22.193 11.741-23.615 16.964 7.594-.406 28.666-18.043 9.548ZM39.1663 29.9181l-16.474-7.378 13.905-12.894 28.205 1.244.717 11.226-26.353 7.802ZM35.5324 90.38l-14.647-15.536 17.827-9.436 26.63 10.424-1.906 14.265-27.904.283ZM67.8564 87.2195l1.582-11.912 17.006-23.872 4.653 1.205-9.523 22.995-13.718 11.584ZM86.8278 47.4042l-17.253-24.519-.575-9.021 13.209 12.341 8.387 22.175-3.768-.976Z"
    />
  </svg>
</template>
