<template>
  <footer
    class="
      p-4
      border-t border-base-300
      footer
      bg-base-300
      text-base-content
      md:flex md:justify-center
    "
  >
    <div class="flex justify-center w-full max-w-screen-lg">
      <div>
        <p>Copyright © 2021 STEAMplug LLC. All rights reserved.</p>
      </div>
    </div>
  </footer>
</template>
